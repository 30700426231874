<template>
  <b-button
    :variant="variant"
    :disabled="disabled || isDownloading"
    @click="startDownload"
  >
    {{ isDownloading ? $t('AppDigitalRecord.downloading') : $t('AppDigitalRecord.download') }}
    <feather-icon
      v-if="!isDownloading"
      icon="DownloadCloudIcon"
    />
    <b-spinner
      v-show="isDownloading"
      small
      type="grow"
    />
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import { downloadFile, Origen } from '@/libs/downloadHelper'
import useCommon from '@/libs/common'

export default {
  components: {
    BButton,
    BSpinner,
  },
  props: {
    idArchivo: {
      type: String,
      required: true,
      default: 'NA_0',
    },
    nombreArchivo: {
      type: String,
      required: true,
      default: 'download.zip',
    },
    titulo: {
      type: String,
      required: false,
    },
    descripcion: {
      type: String,
      required: false,
    },
    origen: {
      type: String,
      default: Origen.ARCHIVOS,
    },
    variant: {
      type: String,
      default: 'success',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDownloading: false,
    }
  },
  methods: {
    startDownload() {
      const onSuccess = message => {
        this.common.toastSuccessWithInterpolated(message)
      }

      const onError = error => {
        this.common.toastError(`${error.message}`)
      }

      const onCancel = message => {
        this.common.toastInfoWithInterpolated(message)
      }

      downloadFile({
        id: this.idArchivo,
        nombreArchivo: this.nombreArchivo,
        titulo: this.titulo,
        descripcion: this.descripcion,
        origen: this.origen,
        item: this,
      }, onSuccess, onError, onCancel)
    },
  },
  setup() {
    const common = useCommon()
    return { common }
  },
}
</script>
