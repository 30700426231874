<template>
  <div>
    <b-card>
      <b-card-header />
      <b-modal
        id="modal-error"
        v-model="showModal"
        :title="$t('AppDigitalRecord.downloadError')"
        ok-only
      >
        <span class="text-danger">{{ $t('AppDigitalRecord.downloadErrorMessage') }}</span>
      </b-modal>
      <b-col
        offset-md="9"
        md="3"
      >
        <b-dropdown
          variant="outline-primary"
          block
          menu-class="w-100"
        >
          <template #button-content>
            <feather-icon
              icon="CommandIcon"
              size="16"
              class="align-middle"
            />
            {{ $t('Components.ListHeader.Actions') }}
          </template>
          <b-dropdown-item @click="changeStateTime">
            <feather-icon icon="CalendarIcon" size="16" />
            <span class="align-middle ml-50">{{ stateTime.value ? $t('Components.List.Filters.DateWhitoutHour') : $t('Components.List.Filters.FormatDate') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>

      <b-table
        ref="refDigitalRecordDownloadsListTable"
        class="position-relative"
        responsive
        :fields="tableColumns"
        :items="fetchDownloads"
      >
        <template #cell(altaPeticion)="data">
          <b-media v-if="stateTime.value" vertical-align="center">
            {{ data.item.altaPeticion | formatDateTime }}
          </b-media>
          <b-media v-else vertical-align="center">
            {{ data.item.altaPeticion | formatDate }}
          </b-media>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.procesamientoPeticion !== '0001-01-01T00:00:00' && data.item.eliminado === '0001-01-01T00:00:00'"
            variant="success"
          >
            {{ $t('AppDigitalRecord.ready') }}
          </b-badge>
          <b-badge
            v-if="data.item.procesamientoPeticion === '0001-01-01T00:00:00'"
            variant="info"
          >
            {{ $t('AppDigitalRecord.processing') }}
          </b-badge>
          <b-badge
            v-if="data.item.eliminado !== '0001-01-01T00:00:00'"
            variant="danger"
          >
            {{ $t('AppDigitalRecord.lapsed') }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <file-downloader
            v-if="data.item.procesamientoPeticion && !(data.item.eliminado !== '0001-01-01T00:00:00')"
            :idArchivo="((data.item.idArchivo == undefined || data.item.idArchivo === '00000') ? '00000' : data.item.idArchivo )"
            :disabled="data.item.procesamientoPeticion === '0001-01-01T00:00:00' || data.item.eliminado !== '0001-01-01T00:00:00'"
            :nombreArchivo="`${((data.item.idArchivo == undefined || data.item.idArchivo === '00000') ? '00000' : data.item.idArchivo )}.zip`"
            :origen="Origen.ARCHIVOS"
            :titulo="((data.item.idArchivo == undefined || data.item.idArchivo === '00000') ? '00000' : data.item.idArchivo )"
            :descripcion="data.item.parametros"
          />
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import store from '@/store'
import {
  defineComponent, onUnmounted, ref,
} from '@vue/composition-api'
import {
  BCard, BCardHeader, BRow, BCol, BButton, BSpinner, BTable, BBadge, BFormCheckbox, BDropdown, BDropdownItem, BMedia,
} from 'bootstrap-vue'
import { Origen } from '@/libs/downloadHelper'
import FileDownloader from '@/components/FileDownloader.vue'
import useDigitalRecordDownloads from './useDigitalRecordDownloads'
import digitalRecordDownloadsStoreModule from './digitalRecordDownloadsStoreModule'

export default defineComponent({
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BTable,
    BBadge,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BMedia,
    FileDownloader,
    Origen,
  },
  setup() {
    const {
      refetchData,
      fetchDownloads,

      refDigitalRecordDownloadsListTable,
      tableColumns,
    } = useDigitalRecordDownloads()

    const dateTimeFormater = JSON.parse(localStorage.getItem('stateTime'))
    if (!dateTimeFormater.find(e => e.key === 'formatDateDowlands')) {
      dateTimeFormater.push({ key: 'formatDateDowlands', value: false })
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }
    const stateTime = ref(dateTimeFormater.find(e => e.key === 'formatDateDowlands'))
    const changeStateTime = () => {
      stateTime.value.value = !stateTime.value.value
      localStorage.setItem('stateTime', JSON.stringify(dateTimeFormater))
    }

    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-digitalRecord'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, digitalRecordDownloadsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const showModal = ref(false)

    return {
      refetchData,
      fetchDownloads,
      stateTime,
      changeStateTime,
      showModal,
      FileDownloader,
      refDigitalRecordDownloadsListTable,
      tableColumns,
      Origen,
    }
  },
})
</script>
<style lang="scss">

</style>
