import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDigitalRecordDownloads() {
  const toast = useToast()

  const refDigitalRecordDownloadsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: i18n.t('AppDigitalRecord.list.requestTime'), key: 'altaPeticion', sortable: false },
    // { label: i18n.t('AppDigitalRecord.list.processedTime'), key: 'procesamientoPeticion', sortable: false },
    { label: i18n.t('AppDigitalRecord.list.user'), key: 'nombreUsuarioPeticion', sortable: false },
    { label: i18n.t('AppDigitalRecord.list.parameters'), key: 'parametros', sortable: false },
    { label: i18n.t('AppDigitalRecord.list.status'), key: 'status', sortable: false },
    { label: i18n.t('Lists.Actions'), key: 'actions', sortable: false },
  ]

  const refetchData = () => {
    refDigitalRecordDownloadsListTable.value.refresh()
  }

  const fetchDownloads = (ctx, callback) => {
    store
      .dispatch('app-digitalRecord/fetchDownloads', {})
      .then(response => {
        response.data.forEach(element => {
          /* eslint no-param-reassign: ["error", { "props": false }] */
          element.downloading = false
        })
        callback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('AppDigitalRecord.fetchError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  return {
    refetchData,
    fetchDownloads,

    refDigitalRecordDownloadsListTable,
    tableColumns,
  }
}
